import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core"
import Lightbox from "react-image-lightbox"
import Layout from "../components/layout/main"
import Lead from "../components/global/text/lead"
import FiltreKullanimAlani from "../components/global/filtre/kullanimalani"

import LightboxImageWrapperCard from "../components/global/lightbox/lightbox-image-wrapper-card"
import FiltreGaleri from "../components/global/carousel/filtregaleri"
import LightboxImageWrapper from "../components/global/lightbox/lightbox-image-wrapper"
import Seo from "../components/global/seo/seo.jsx"
const EndustriyelFiltre = () => {
  const data = useStaticQuery(graphql`
    query {
      resim1: file(
        relativePath: { eq: "filtre-galeri/endustriyelfiltre5.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim2: file(
        relativePath: { eq: "filtre-galeri/endustriyelemisfiltresi.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim3: file(
        relativePath: { eq: "filtre-galeri/endustriyelfiltre.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim4: file(
        relativePath: { eq: "filtre-galeri/endustriyelfiltre2.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim5: file(
        relativePath: { eq: "filtre-galeri/endustriyelfiltre3.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim6: file(
        relativePath: { eq: "filtre-galeri/endustriyelfiltre4.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      resim7: file(
        relativePath: { eq: "filtre-galeri/endustriyelfiltreimalati.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 360) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          full: fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const fullImgArray = [
    data.resim1.childImageSharp.full.src,
    data.resim2.childImageSharp.full.src,
    data.resim3.childImageSharp.full.src,
    data.resim4.childImageSharp.full.src,
    data.resim5.childImageSharp.full.src,
    data.resim6.childImageSharp.full.src,
    data.resim7.childImageSharp.full.src,
  ]
  const thumbArray = [
    { imgName: "resim2", imgAlt: "Endüstriyel Filtre İmalatı" },
    { imgName: "resim3", imgAlt: "Endüstriyel Filtre İmalatı" },
    { imgName: "resim4", imgAlt: "Endüstriyel Filtre İmalatı" },
    { imgName: "resim5", imgAlt: "Endüstriyel Filtre İmalatı" },
    { imgName: "resim6", imgAlt: "Endüstriyel Filtre İmalatı" },
    { imgName: "resim7", imgAlt: "Endüstriyel Filtre İmalatı" },
  ]
  const [isOpen, toggleOpenLightbox] = React.useState(false)
  const toggleLightbox = opened => {
    toggleOpenLightbox(opened)
  }
  const [imgIndex, setIndex] = React.useState(0)
  const setImgIndex = imageIndex => {
    setIndex(imageIndex)
  }
  const openLightBox = iIndex => {
    toggleLightbox(true)
    setImgIndex(iIndex)
  }
  const setPrevImgIndex = () => {
    const newIndex = (imgIndex + fullImgArray.length - 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  const setNextImgIndex = () => {
    const newIndex = (imgIndex + 1) % fullImgArray.length
    setImgIndex(newIndex)
  }
  return (
    <Layout>
      <Seo
        title="Endüstriyel Filtre İmalatı - Paslanmaz Filtre Erez Kaynak " 
        description="Firmamız proje çizimine yada numuneye göre müşterilerimizin istekleri doğrultusunda paslanmaz endüstriyel filtre imalatı yapmaktadır. Göz aralıkları mikronlar ile ölçülebilen mesh telleri kullanılarak istediğiniz ölçü, numune yada projeye göre paslanmaz endüstriyel filtre imalatını gerçekleştirmekteyiz."
        keywords="Endüstriyel filtre imalatı,Endüstriyel filtre fiyatı,Endüstriyel filtre fiyatları,Endüstriyel paslanmaz filtre,Endüstriyel filtreci,Endüstriyel tel filtre,Endüstriyel filtre tasarımı,Endüstriyel filtre istanbul,Endüstriyel elek imalatı,Endüstriyel filtresi,Endüstriyel filtre imalat,paslanma endüstriyel filtre,Endüstriyel Filtre İmalatı - Erez Kaynak,Endüstriyel Filtre İmalatı"
        url="endustriyel-filtre-imalati"
        imgurl={data.resim1.childImageSharp.thumb.src}
      />
      <Box py={3}>
        <Paper elevation={4}>
          <Grid container direction="row" alignItems="center">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box p={3}>
                <Typography component="h1" variant="h4">
                  <Box textAlign="center" fontWeight={500} mt={3}>
                    Paslanmaz Endüstriyel Filtre İmalatı
                  </Box>
                </Typography>
                <Lead>
                  Firmamız proje çizimine yada numuneye göre müşterilerimizin
                  istekleri doğrultusunda{" "}
                  <span>paslanmaz endüstriyel filtre</span> imalatı yapmaktadır.
                </Lead>
                <Lead>
                  Göz aralıkları mikronlar ile ölçülebilen mesh telleri
                  kullanılarak istediğiniz ölçü, numune ya da projeye göre{" "}
                  <span>paslanmaz endüstriyel filtre</span> imalatını
                  gerçekleştirmekteyiz.
                </Lead>
                <Lead>
                  Detaylı bilgi ya da fiyat teklifi almak için bizi arayınız.
                </Lead>
                <Box textAlign="center" py={3}>
                  <Link to="/iletisim" style={{ textDecoration: "none" }}>
                    <Button variant="outlined" color="secondary">
                      İLETİŞİM BİLGİLERİ
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box px={3} py={3}>
                <div onClick={() => openLightBox(0)}>
                  <LightboxImageWrapper
                    fluid={data.resim1.childImageSharp.thumb}
                    imgAlt="Endüstriyel Filtre İmalatı"
                    imgClass=""
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box py={3}>
        <Grid container direction="row" spacing={3}>
          {thumbArray.map((resim, index) => (
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div onClick={() => openLightBox(index + 1)}>
                <LightboxImageWrapperCard
                  fluid={data[resim.imgName].childImageSharp.thumb}
                  lightbox={true}
                  imgAlt={resim.imgAlt}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box my={3}>
        <Paper elevation={4}>
          <Box p={3}>
            <FiltreKullanimAlani
              title="Paslanmaz Endüstriyel Filtre Kullanım Alanları"
              align="center"
            />
          </Box>
        </Paper>
      </Box>

      <Box py={3}>
        <FiltreGaleri />
      </Box>

      {isOpen && (
        <Lightbox
          mainSrc={fullImgArray[imgIndex]}
          nextSrc={fullImgArray[(imgIndex + 1) % fullImgArray.length]}
          prevSrc={
            fullImgArray[
              (imgIndex + fullImgArray.length - 1) % fullImgArray.length
            ]
          }
          onCloseRequest={() => toggleLightbox(false)}
          onMovePrevRequest={setPrevImgIndex}
          onMoveNextRequest={setNextImgIndex}
        />
      )}
    </Layout>
  )
}
export default EndustriyelFiltre
