import React from "react"
import Img from "gatsby-image"
import { ZoomIn } from "@material-ui/icons"
import { Avatar, Card, CardHeader, CardMedia } from "@material-ui/core"
const LightboxImageWrapperCard = ({ fluid, imgAlt, lightbox }) => {
  return (
    <Card elevation={4}>
      <CardHeader
        avatar={
          <Avatar
            style={{ backgroundColor: "#ef5350" }}
            aria-label="EREZ KAYNAK"
          >
            E
          </Avatar>
        }
        title="EREZ KAYNAK"
        subheader={imgAlt || "Erez Kaynak Paslanmaz Filtre İmalatı"}
      />
      <CardMedia className={lightbox ? "gallery-image-wrapper" : ""}>
        <Img
          fluid={fluid}
          loading="eager"
          fadeIn={false}
          alt={imgAlt || "Erez Kaynak Paslanmaz Filtre İmalatı"}
        />
        {lightbox && (
          <div className="overlay">
            <ZoomIn />
          </div>
        )}
      </CardMedia>
    </Card>
  )
}
export default LightboxImageWrapperCard
